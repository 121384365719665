import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';
import { Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import type { PpWC } from '@noah-labs/fe-shared-ui-shared';
import { Helmet } from 'react-helmet';
import { useDesktop } from '../hooks/useDesktop';
import { coreThemeConstants } from '../theme/coreThemeConstants';
import { AppNavbar } from './AppNavbar';

type PpAppContainer = PpWC & {
  backgroundColor?: string;
  BottomNavbarSlot?: React.ReactElement;
  fontColor?: string;
  headTitle?: string;
  isVisible?: boolean;
  SideNavbarSlot?: React.ReactElement;
};

/**
 * Renders the main application container with optional side and bottom navigation bars. Will normally take AppHeader, AppMain, and AppFooter as children.
 *
 * @param backgroundColor - The background color of the container.
 * @param BottomNavbarSlot - The content to be rendered in the bottom navigation bar.
 * @param children - The content to be rendered inside the container.
 * @param fontColor - The font color of the container.
 * @param headTitle - The title of the HTML document.
 * @param isVisible - Determines whether the container is visible or hidden.
 * @param SideNavbarSlot - The content to be rendered in the side navigation bar.
 * @returns The rendered application container.
 */
export function AppContainer({
  backgroundColor,
  BottomNavbarSlot,
  children,
  fontColor,
  headTitle,
  isVisible = true,
  SideNavbarSlot,
}: PpAppContainer): React.ReactElement {
  const theme = useTheme();

  const isDesktop = useDesktop();
  const shouldDisplayBottomBar = (!isDesktop || !SideNavbarSlot) && BottomNavbarSlot;

  const styles = {
    container: [
      css`
        min-height: 100%;
      `,
    ],
    innerContainer: css`
      width: 100%;
      min-height: 100%;
      display: flex;
      flex-direction: column;
      background-color: ${theme.palette.grayscale.offWhite};

      ${backgroundColor &&
      css`
        background: ${backgroundColor};
      `}

      ${fontColor &&
      css`
        color: ${fontColor};
      `}
    `,
    sidebar: css`
      box-shadow: ${theme.shadows[2]};
      z-index: ${theme.zIndex.floatingBar};
    `,
  };

  if (!isVisible) {
    styles.container.push(visuallyHidden as SerializedStyles);
  }
  return (
    <Grid container aria-hidden={!isVisible} css={styles.container}>
      {headTitle && (
        <Helmet>
          <title>{headTitle}</title>
        </Helmet>
      )}
      {isDesktop && SideNavbarSlot && (
        <Grid
          css={styles.sidebar}
          size={{ lg: coreThemeConstants.sidebarColumns, md: false, sm: false, xs: false }}
        >
          {SideNavbarSlot}
        </Grid>
      )}
      <Grid size={{ lg: isDesktop && SideNavbarSlot ? 10 : 12, md: 12, sm: 12, xs: 12 }}>
        <div css={styles.innerContainer}>
          {children}
          {shouldDisplayBottomBar && <AppNavbar>{BottomNavbarSlot}</AppNavbar>}
        </div>
      </Grid>
    </Grid>
  );
}
